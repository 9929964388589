<template>
  <div>
    <div class="flex-def flex-cCenter flex-zBetween" style="margin-bottom: 1rem">
      <div class="y-desc" style="font-size: 1rem">商品信息</div>
      <el-button @click="$router.back()" type="text" size="mini">返回列表</el-button>
    </div>
    <el-card style="min-height: 45rem">
      <el-tabs :before-leave="tabsChange" v-model="tabsActive">
        <el-tab-pane label="基础信息" name="basic">
          <basic @theme-change="themeChange" v-model="form"
                 @form-submit="submit"></basic>
        </el-tab-pane>
        <el-tab-pane label="商品详情" name="detail">
          <detail v-model="form" @form-submit="submit"></detail>
        </el-tab-pane>
        <el-tab-pane label="详情页轮播图" name="banner">
          <banner @reload="load" v-model="form.banner" @form-submit="submit"></banner>
        </el-tab-pane>
        <el-tab-pane label="库存/规格" name="spec">
          <spec @reload="load" v-model="form" @form-submit="submit"></spec>
        </el-tab-pane>
        <el-tab-pane label="分润设置" name="award">
          <award v-model="form" @form-submit="submit"></award>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import basic from "@/view/shop/goods/edit/basic";
import detail from "@/view/shop/goods/edit/detail";
import spec from "@/view/shop/goods/edit/spec";
import banner from "@/view/shop/goods/edit/banner";
import award from "@/view/shop/goods/edit/award";
export default {
  components: {
    basic,
    detail,
    spec,
    banner,
    award,
  },
  name: "edit",
  data() {
    return {
      form: {
        sort: 0,
        title: "",
        desc:"",
        cate_id: 0,
        cate_parent_id: 0,
        fake_sales: 0,
        fee: 0,
        original_price: 0,
        stock: 0,
        home_pic:"",
        pic: "",
        detail: "",
        status: false,
        delivery_fee: 0,
        spec:false,
        banner:"",
        recommend:false,
        shipping_id:0,

        award_base_fee:0,
        award_base_fee_unit:"",

        invite_award_scale:0,
        invite_2_award_scale:0,
      },
      theme_ids:[],
      tabsActive: "basic",
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.load(parseInt(this.$route.query.id));
    }
  },
  methods: {
    load(edit_id) {
      this.$u.api.shop.goods.one({id: parseInt(edit_id)}).then(res => {
        this.form = {...res}
      })
    },
    themeChange(val) {
      this.theme_ids = val
    },
    submit() {
      this.$u.api.shop.goods.edit({
        goods:this.form,
        theme_ids:this.theme_ids,
      }).then(() => {
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
    tabsChange(e) {
      let arr = ["invite","spec"]
      if (arr.includes(e) && !this.form.id) {
        this.$message.error("请先创建商品信息")
        return false
      }
    },
  }
}
</script>

<style scoped>

</style>