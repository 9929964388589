<template>
  <div>
    <y_editor v-model="form.detail"></y_editor>
    <div style="margin-top: 1rem">
      <el-button @click="$emit('form-submit')" type="primary">提交</el-button>
    </div>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
export default {
  name: "detail",
  components:{y_editor},
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{}
      }
    },
  },
  watch:{
    modelVal(){
      this.form = this.modelVal;
    },
  },
  data(){
    return{
      form:this.modelVal,
    }
  }
}
</script>

<style scoped>

</style>